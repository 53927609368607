import { Bar, mixins } from "vue-chartjs";
import plugin from "chartjs-plugin-annotation";
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: ["options"],
  mounted() {
    this.addPlugin(plugin);
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, this.options);
  },
  watch: {
    "options.scales.yAxes.0.ticks.suggestedMax": function() {
      this.renderChart(this.chartData, this.options);
    }
  },
  methods: {
    launchUpdate() {
      this.renderChart(this.chartData, this.options);
    }
  }
};
